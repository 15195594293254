<template>
  <nav class="mdc-list pr-4">
    <mdc-button
      v-for="option in options"
      :id="`${option.view}-${_uid}`"
      :key="option.view"
      ripple
      class="mdc-list-item cursor-pointer w-full"
      @click.native="setEmailView(option)"
    >
      <i
        class="material-icons mdc-list-item__graphic"
        aria-hidden="true"
        v-text="option.icon"
      />
      <span class="mdc-list-item__text" v-text="option.label" />
    </mdc-button>
  </nav>
</template>

<script>
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: {
    MdcButton,
  },

  data: () => ({
    options: [
      { label: 'All Posts', view: 'all', icon: 'email' },
      { label: 'Scheduled Posts', view: 'scheduled', icon: 'alarm' },
      { label: 'Sent Posts', view: 'sent', icon: 'check_circle' },
      { label: 'Drafts', view: 'draft', icon: 'drafts' },
      { label: 'Trash', view: 'trash', icon: 'delete' },
    ],
  }),

  mounted() {
    this.setActiveClass();
  },

  methods: {
    setEmailView(option) {
      if (this.$store.state.emailSort.view !== option.view)
        this.$store.commit('setView', option.view);
      if (
        !(
          this.$store.state.emailSort.view === option.view &&
          this.$route.path !== '/post'
        )
      )
        this.$store.commit('resetPage');
      if (this.$route.name !== 'posts') this.$router.push({ name: 'posts' });
      this.setActiveClass();
    },
    setActiveClass() {
      const selName = 'mdc-list-item--selected';
      this.options.forEach((option) => {
        const butt = document.getElementById(`${option.view}-${this._uid}`);
        if (this.$store.state.emailSort.view === option.view)
          butt.classList.add(selName);
        else butt.classList.remove(selName);
      });
    },
  },
};
</script>
